import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: './home/home.module#HomeModule'
  },
  {
    path: 'cars',
    loadChildren: './cars/cars.module#CarsModule'
  },

  {
    path: 'error/not-found',
    loadChildren: './error/error.module#ErrorModule'
  },
  {
    path: '**',
    redirectTo: '/error/not-found',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { useHash: true }),
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
